
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: grey;
    opacity: .9;
  }

  .modalHeader{
    background-image: url(/src/components/images/navbarbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328; 
    display: flex;
    justify-content: center;
    text-align: center;
    
  }

  .modalFormContainer{
    text-align: center;
    margin-top: 30px;
  }
  .modalHeaderText{
    color: white;
    font-size: 18px;
    margin: 30px;
  }

  .modalLabel{
    color: white;
    margin: 20px;
    font-size: 15px;
    margin-top: 20px;
  }

.modalServices{
  margin-top: 40px;
  background-color: white;
}

.modalHeaderExitButton:hover{
  cursor:pointer;
}

.serviceLabel{
  display: block;
  margin-top: 40px;
}

.servicesFormSet{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:-30px;
}

.modalHeaderExitButton{
  background: transparent;
  border: none;
  position: absolute;
  right: 0px;

}

.modalHeaderExitText{
  color: white;
  font-weight: bold;
  font-size: 15px;
  margin: 5px;
}

.modalButton{
  border-radius: 4px;
  background-image: url(/src/components/images/bluewoodgrain.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #981B1D;
  color: white;
  font-size: 18px;
  padding: 14px;
  margin-top: 40px;
}

.modalButton:hover{
  color: white;
  background-color: #cf331f;
  cursor:pointer;
}

.modalInput{
  font-size: 15px;
  margin-top: 30px;
}

.Modal {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background-image: linear-gradient(to right, #020408, #262b38);
  background: repeating-linear-gradient(
  45deg,
   #212328,
   #212328 2px,
   #262b38 2px,
   #262b38 20px
);
font-family:Arial, Helvetica, sans-serif;
  width: 40%;
  margin-left: 25%;
  opacity: 1;
  height: auto;
  
}



@media screen and (max-width: 1000px) {
  .Modal{
    width: 55%;
    left: -15%;
  
    margin-left: 35%;

   
}
  

}

@media screen and (max-width: 800px) {
    .Modal{
        width: 95%;
        left: -15%;
       
        margin-left: 17.5%;
  
       
    }

    .modalLabel{
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
    }
    .modalInput{
        margin-top: 0px;
        font-size: 12px;
    }
    .modalServices{
        display: block;
        text-align: center;
    }
    .modalHeaderText{
        margin: 10px;
        font-size: 20px;
    }

    .modalHeader{
      padding: 20px;
    }
   

}