.footerContainer{
    background-image: url(/src/components/images/navbarbackground.svg);
    background-color: #212328;
    background-size: contain;
    background-repeat: repeat;
}

.row{
    padding-top: 20px;
}


.logoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerLogo{
    height: 150px;
    width: 150px;
}

.footerHeader{
    color: white;
    font-size: 17px;
    text-shadow: 4px 4px 4px #000000; 
  
}

.footerText{
    color: white;
    font-size: 23px;
    font-weight: 800;
}

.footerColumn{
    width: 100%;
  text-align: center;
}

.footerButton{
    
    border-radius: 4px;
    background-image: url(/src/components/images/bluewoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #981B1D;
    color: white;
    font-size: 25px;
    padding: 14px;
}

.footerButton:hover{
    color: white;
    background-color: #cf331f;
    cursor:pointer;
}
.copyrightContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyrightText{
    color: white;
    font-size: 13px;
    margin-top: -20px;
    margin-bottom: 20px;
}

.footerLiContainerCredits{
    display: flex;
    justify-content: right;
    align-items: right;
}

.footerLiContainerNavbar{
    display: flex;
    justify-content: left;
    align-items: left;
}

.footerNavbarLi{
    color: white;
    font-size: 15px;
    padding: 10px;
    border-left: 1px solid white;
    list-style-type: none;
}

.footerCreditLi{
    color: white;
    font-size: 12px;
    padding: 10px;
    list-style-type: none;
}

.footerMobileCredits{
    color: white;
    font-size: 15px;
    padding: 10px;
    list-style-type: none;
    text-align: center;
}

.footerMobileNavbarLi{
    color: white;
    font-size: 15px;
    padding-left: 9px;
    padding-right: 9px;
    border-left: 1px solid white;
    list-style-type: none;
}


.creditColumn{
    width: 48%;
}

.creditDesktopContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2%;
}

.creditMobileContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6%;
}

a {
    list-style: none;
    color: white;
    text-decoration: none;
}


a:hover {
    text-shadow: 1px 1px black;
    text-decoration: underline;
    text-decoration-color: white;
}

.socialMediaButton{
    background-color: transparent;
    border: none;
    font-size: 30px;
    padding: 30px;
}
 

@media screen and (max-width: 800px) {
    .footerContainer{
        background-image: url(/src/components/images/redwoodgrain.svg);
    }
   .creditDesktopContainer{
    display: none;
   }

  
}

@media screen and (min-width: 800px) {
    .creditMobileContainer{
     display: none;
    }

    .footerMobileCredits{
        display: none;
    }
 }