.reviewsContainer{
    
    padding-bottom: 20px;
    margin: 20px;
    height: 250px;
  
}

.reviewsTitle{
    width: 100%;
    color: white;          
    text-shadow: 4px 4px 4px #000000; 
    text-align: center;
    font-size: 35px;
    border-right: 6px solid #981B1D;

}

.reviewsText{
    width: 100%;
    color: white;          
    text-shadow: 4px 4px 4px #000000; 
    text-align: center;
    font-size: 25px;
    margin: 15px;
    
}

.starContainer{
    color: gold;
    display: flex;
    align-items:center;
    justify-content:center;
   
}

.textColumn{
    float: left;
    width: 32.5%;
    margin: 25px;
}

.reviewColumn{
    width: 66%;
    display: flex;
    align-items:center;
    justify-content:center;
   
}





@media screen and (max-width: 1000px) {

    .textColumn{
        width: 100%;
        border-right: none;
        border-bottom: 6px solid #981B1D;
        padding-bottom: 20px;
        margin: 0px;

    }

 

    .reviewColumn{
        width: 100%;
        
    }

    .reviewsTitle{
        border-right: none;
        font-size: 25px;
       
    }

    .reviewsText{
        font-size: 25px;
    }

}

@media screen and (max-width: 1000px) {
    .reviewsContainer{
        height: 350px;
    }
}



