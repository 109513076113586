.estimateConfirmationContainer{
    background-image: linear-gradient(to right, #212328, #262b38);
    background: repeating-linear-gradient(
    45deg,
     #212328,
     #212328 2px,
     #262b38 2px,
     #262b38 20px
);
font-family:Arial, Helvetica, sans-serif;
height: 100%;
text-align: center;

}

.estimateConfirmationTextContainer{
    margin: 10%;
}

.estimateConfirmationText{
    color: white;
}