.errorContainer{
    background-image: linear-gradient(to right, #212328, #262b38);
    background: repeating-linear-gradient(
    45deg,
     #212328,
     #212328 2px,
     #262b38 2px,
     #262b38 20px
);
font-family:Arial, Helvetica, sans-serif;

}

.logoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

}

.errorLogo{
    width: 300px;
}



.textContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    text-align: center;
}

.errorHeader{
    font-size: 60px;
    color: white;
    text-shadow: 4px 4px 4px #000000;
}


.errorText{
    font-size: 40px;
    color: white;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
}

@media screen and (max-width: 800px) {

    .errorLogo{
        width: 200px;
    }

}
