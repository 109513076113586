.aboutContainer{
  
    margin: 20px;
}

.aboutTitle{
    width: 100%;
    color: white;  
    text-shadow: 4px 4px 4px #000000; 
    text-align: center;
    border-bottom: 6px solid #981B1D;
    font-family:Georgia, serif;
    
}

.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutParagraph{
    color: white;
    font-size: 18px;
    text-indent: 60px;
    margin: 20px;
}

.textAboutColumn{
    width: 50%;
}

.imageAboutColumn{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;  
     
}

.aboutImage{
    width: 80%;
    height: auto;
    margin: 20px;
    border-radius: 8px;
}

.aboutMobileImage{
   display: none;
   width: 100%;
   height: auto;
   border-radius: 8px;
}



@media screen and (max-width: 1000px) {
    .textAboutColumn{
        width: 100%;
  
    }
    .imageAboutColumn{
        display: none;
     
    }

    .aboutMobileImage{
        display: flex;
        width: 50%;
        margin: 20px;
        height: auto;
        
    }
    
}

@media screen and (max-width: 800px) {
    .aboutMobileImage{
        display: flex;
        width: 80%;
        margin: 20px;
        
    }
}