.servicesContainer{
    background-image: url(/src/components/images/navbarbackground.svg);
    background-color: #212328;
    background-size: contain;
    background-repeat: repeat;
    display: flex;
    padding-bottom: 50px;
    margin: 15px;
   

}

.servicesImageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.servicesImage{
   
    width: 80%;
    height: auto;
    margin: 20px;
    border-radius: 12px;
   
  
}


.titleContainer{
   text-align: center;
   
}
.servicesTitle{
    font-family:Georgia, serif;
    width: 100%;
    color: white;  
    text-shadow: 4px 4px 4px #000000; 
}   

.IconContainer{
    width: 100%;  
    
}

.serviceRow{
    height: 150px;
}
  .column {
   float: left;
    width: 25%;
    margin-top: 25px; 
    display: flex;
    justify-content: center;
    height: 100%;
  }

  

  


  .servicesIcon:hover {
    text-shadow: 1px 1px black;
    border: 1px solid white;
    cursor: pointer;
}

.mobileIconContainer{
    display: none;
}




@media screen and (max-width: 1000px) {
    
    .servicesImage{
        display: none;
      }

      .servicesTitleContainer{
        display: block;
        padding-top: 30px;
    }

    .servicesTitle{
        text-align: center;
        padding-left: 0px;
    } 

}

@media screen and (max-width: 800px) {

   
    .mobileIconContainer{
        display: block;
    }

    .desktopIconContainer{
        display: none;
    }

    .column{
        width: 32.4%;
    }
  
    
    .servicesImage{
        display: none;
      }

      .servicesTitleContainer{
        display: block;
        padding-top: 30px;
    }

    .servicesTitle{
        text-align: center;
        padding-left: 0px;
    } 
    .serviceRow{
        height: 150px;
    }

}

@media screen and (max-width: 700px) {
    .servicesContainer{
        background-image: url('./images/redwoodgrain.svg');
        background-size: contain;
        background-repeat: repeat; 
        background-color: #212328;
        margin: 0px;
       
    }
    
    .servicesImage{
        display: none;
      }

      .servicesTitleContainer{
        display: block;
        padding-top: 30px;
    }

    .servicesTitle{
        text-align: center;
        padding-left: 0px;
    } 


}