.contactContainer{
    background-image: linear-gradient(to right, #212328, #262b38);
    background: repeating-linear-gradient(
    45deg,
     #212328,
     #212328 2px,
     #262b38 2px,
     #262b38 20px
    );

font-family:Arial, Helvetica, sans-serif;
}

.contactHeader{
    font-family:Georgia, serif;
    color: white;
    text-shadow: 4px 4px 4px #000000;
    border-bottom: 6px solid #981B1D; 
}

.contactHeaderContainer{
    display: block;
    text-align: center;
}

.contactText{
    color: white;
    text-align: center;
    font-size: 20px;
    margin: 30px;
}

.formSet{
    text-align: center;
}

.formLabel{
    display: block;
    color: white;
   
    font-size: 20px;
    margin: 30px;

}

.formInput{
    width: 30%;
    height: 30px;
}

.formTextAreaInput{
    width: 50%;
    height: 120px;
}

.contactButton{
    border-radius: 4px;
    background-image: url(/src/components/images/bluewoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #981B1D;
    color: white;
    font-size: 20px;
    padding: 14px;
    width: 200px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.contactButton:hover{
    color: white;
    background-color: #cf331f;
    cursor:pointer;
}

@media screen and (max-width: 900px) {
    .formInput {
        width: 75%;
     }
     .formTextAreaInput{
        width: 80%;
     }
    
    }
