.coverContainer{
    background-image: url(/src/components/images/coverPhoto.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
    
}

.desktopTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktopSubTextContainer{
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentContainer{
    padding-top: 200px;
   
}

.desktopCoverText{
    font-family:Georgia, serif;
    display: flex;
    color: white;
    text-align: center;
    text-shadow: 4px 4px 4px #000000;
    font-size: 55px;
    margin-top: -30px;
}

.secondHeader{
    border-bottom: 6px solid #981B1D;
}

.desktopCoverSubText{
   
   text-align: center;
    color: white;
    text-shadow: 4px 4px 4px #000000;
    font-size: 35px;
    margin-top: -30px;
    
}

.mobileCoverText{
    font-family:Georgia, serif;
    display: none;
    position: relative;
    color: white;
    text-align: center;
    text-shadow: 4px 4px 4px #000000;
    font-size: 30px;
    margin: 20px;
    border-bottom: 6px solid #981B1D;
   
}

.coverButtonContainer{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
}

.coverButton{
    
    border-radius: 4px;
    background-image: url(/src/components/images/bluewoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #981B1D;
    color: white;
    font-size: 20px;
    padding: 10px;
    align-items: center;
    padding: 20px;
    font-size: 30px;
    
}

.coverButton:hover{
    color: white;
    background-color: #cf331f;
    cursor:pointer;
    
}

@media screen and (min-width: 700px) {
    .coverContainer{
    background-image: url(/src/components/images/coverPhoto.jpeg);
        
    }
}

@media screen and (max-width: 700px) {
    .coverContainer{
        background-size: cover;  
        background-attachment: none; 
        background-image: url(/src/components/images/mobileCoverPhoto.jpg);
        background-attachment: unset;
        height: 700px;
        

       
    }

    .contentContainer{
        padding-top: 100px; 
    }

    .desktopSubTextContainer{
        display: none;
    }
    .desktopCoverText{
        font-size: 30px;
    }

    .coverText{
        font-size: 25px;
    }

    .coverButton{
        font-size: 20px;
        padding: 10px;
        align-items: center;
    }
    .mobileCoverText{
        display: block;
    }

    .desktopTextContainer{
        display: none;
    }

    .desktopSubTextContainer{
        display: none;
    }
    
}


    
