.missionContainer{
    margin: 15px;
}

.missionTitle{
    font-family:Georgia, serif;
    width: 100%;
    color: white;          
    text-shadow: 4px 4px 4px #000000; 
    text-align: center;
    font-size: 38px;
    text-align: center;
    border-bottom: 6px solid #981B1D;
}

.missionText{
    color: white;
    font-size: 18px;
}

.missionIcon{
    color: white;
    font-size: 35px;
}

.iconContainer{
    text-align: center;
}

.missionColumn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
  .missionContainer{
    display: none
  }
}