.serviceContainer{
    background-image: linear-gradient(to right, #212328, #262b38);
    background: repeating-linear-gradient(
    45deg,
     #212328,
     #212328 2px,
     #262b38 2px,
     #262b38 20px
    );

font-family:Arial, Helvetica, sans-serif;
}

.serviceHeader{
    font-family:Georgia, serif;
    color: white;
    text-shadow: 4px 4px 4px #000000;
    border-bottom: 6px solid #981B1D; 
    font-size: 40px;
  
}

.serviceHeaderContainer{
    display: block;
    text-align: center;
    margin: 40px;
}

.smallColumn{
    width: 30%;
}

.bigColumn{
    width: 60%;
}

.serviceParagraph{
    color: white;
    font-size: 18px;
    margin-left: 60px;
    margin-right: 60px;
    text-indent: 50px;
}

.imgContainer{
    text-align: center;
   
}

.serviceImage{
    height: 250px; 
    border-radius: 8px;
    
    margin: 0 auto;
}

.estimateText{
    color: white;
    text-shadow: 4px 4px 4px #000000;
    font-size: 30px;
    text-align: center;
}

.serviceButtonContainer{
    text-align: center;
    padding-bottom: 50px;
}

.serviceButton{
    border-radius: 4px;
    background-image: url(/src/components/images/bluewoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #981B1D;
    color: white;
    font-size: 25px;
    padding: 14px;
}

.serviceButton:hover{
    color: white;
    background-color: #cf331f;
    cursor:pointer;
}

.estimateTitle{
    text-transform: lowercase;
}

.locationParagraph{
    text-transform: lowercase;
}

@media screen and (max-width: 1000px) {
    .smallColumn{
        width: 100%;
    }

    .bigColumn{
        width: 100%;
    }

    .serviceImage{
        width: 80%;
        
    }
  
    
}
