.servicesIconContainer{
    border-radius: 30%; 
    width: 150px;
    display: flex;
  
}

.iconContainer{
    max-width: 100%;
    padding: 10px;
  
    background-size: cover;
    background-repeat: no-repeat;
  
    border-radius: 30%;


}

.icon{
    width: 50%;
    height: 50%;
    display: block;
    margin: auto;
    height: auto;
  
}

.iconText{

 text-align: center;
 font-size: 17px;
 color: white;
  
}

@media screen and (max-width: 700px) {
    .servicesIconContainer{
        border-radius: 50%; 
        width: 100px;
        display: flex;
      
    }
       
    

}