.navbarDesktopContainer{
   
    background-image: url(/src/components/images/navbarbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328;
    display: flex;
    align-items: center;
}

.mobileNavbarContainer{
   
    background-image: url(/src/components/images/navbarbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328;
    display: none;
    align-items: center;
    

}

.logo-container {
    margin: 5px;
    position: relative;
    float: left;
    
}

.logo {
    height: 120px;
    width: 120px;
    margin-left: 20px;
    
}

.menu-list-container{   
    padding-top: 10px;

}

.full-screen-ul{
    position: relative;
    float: left;
    margin-right: 180px;
}



.listColumn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoColumn{
    width: 8%;
}

.buttonColumn{
    width: 20%;
}

.navbarMobileEndColumn{
    width: 20%;
}

.navbarMobileMiddleColumn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.full-screen-li {
    margin-left: 50px;
    display: inline;
}

a {
    list-style: none;
    color: white;
    text-decoration: none;
}

li {
    list-style-type: none;
    font-size: 18px;
}

.triple-bars:hover{
    cursor:pointer;

}
a:hover  {
    text-shadow: 1px 1px black;
    text-decoration: underline;
    text-decoration-color: white;
}

.triple-bars{
    display: none;
    color: white;
}

.navbar-button-container{
    position: relative;
    float: right;
    
}

.navbar-button{
   
    margin-right: 30px;
    border-radius: 4px;
    background-image: url(/src/components/images/bluewoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #981B1D;
    color: white;
    font-size: 15px;
    padding: 14px;
    
}

.mobileLi{
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    width: 300px;

}

.navbar-button:hover{
    color: white;
    background-color: #cf331f;
    cursor:pointer;
}

.phoneButton{
    background: transparent;
    border: none;
}


.mobile-menu {
    
    background-image: url(/src/components/images/navbarbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328;
    display: flex;
    margin-top: -20px;
    justify-content: center;
    align-items: center;

}

.responsive {
    display: flex;
}

.select-menu-items {
   display: flex;
    
}



.phoneContainer{
    display: none;
}

.phoneIcon{
    font-size: 15px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-left: 33px;
}

.phoneTextContainer{
    display: inline;

    
}
.phoneIcon{
    display: inline;
    font-size: 14px;
}

.phoneText{
    font-size: 14px;
    font-weight: 750;
    margin-left: 5px; 

    display: inline; 
    color: white;
    
}

@media screen and (max-width: 1200px) {
.full-screen-ul {
    padding-left: 110px;  
 }

}

@media screen and (max-width: 1000px) {
    .full-screen-ul {
        padding-left: 20px;  
     }


    .mobile-menu.responsive a{
        display: block;
        margin: 20px;
        text-align: center;

    }

    .select-menu-items.mobile-menu a {
      
        float: none;
        display: block;

    }
 
    .navbar-button-container{
        margin-right: 20px;

    }
    .navbar-button{
        display: inline-block;
        margin-right: 0px;
  
    }
   
  }

  @media screen and (max-width: 900px) {

    .navbarDesktopContainer{
        display: none;
    }

    .mobileNavbarContainer{
        display: flex;
    }


    .phoneContainer{
        display: inline;
    }


  .logo {
    height: 80px;
    margin-left: 20px;
}

.buttonColumn{
    width: 10%;
}

.logoColumn{
    width: 10%;
}

.triple-bars{
   background: transparent;
   border: none;
    display: block;
    position: relative;
    float:right;
    color:white;
    margin-right: 30px;
    margin-top: 10px;
  }
 
  }

  @media screen and (min-width: 900px) {
    .mobile-menu {
        display: none;
    
    }

  }

  @media screen and (max-width: 410px) {
    .phoneIcon {
        display: none;
    
    }

    .phoneButton {
        display: none;
    }

  }



