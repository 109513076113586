.stepsContainer{
    background-image: url(/src/components/images/steps.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    padding-top: 20px;
    padding-bottom: 100px;
    margin: 15px;
  }


.stepsTitle{
    font-family:Georgia, serif;
    width: 100%;
    color: white;          
    text-shadow: 4px 4px 4px #000000; 
    text-align: center;
    font-size: 38px;
}



.row{
    display: flex;
    justify-content: center;
}

.cardColumn {
    width: 15%;
    background-image: url(/src/components/images/redwoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328;
    margin-top: 25px; 
    
    color: white;
    padding: 2%;
    margin: 1%;
    text-align: center;
    border-radius: 8px;
    border-style: solid;
    border-width: 5px;
    border-color: #212328;
    font-size: 15px;
    text-shadow: 4px 4px 4px #000000; 
}

.stepsHeader{
    padding-right: 20px;
    border-right: 6px solid #981B1D;
}

.paragraphColumn {
    width: 15%;
    background-image: url(/src/components/images/redwoodgrain.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #212328;
    margin-top: 25px; 
    color: white;
    padding: 2%;
    margin: 1%;
    text-align: left;
    border-radius: 8px;
    border-style: solid;
    border-width: 5px;
    border-color: #212328;
    font-size: 15px;
    margin-top: -60px;
    text-indent: 30px;
}

.stepsIcon{
    width: 70px;
    height: 70px;
  }



  @media screen and (max-width: 1200px) {
    .stepsIcon{
        width: 50%;
        height: 30%;
        margin-right: 10px;
      }
  }

@media screen and (max-width: 1000px) {
    .cardColumn{
        width: 50%;
        padding: 0%;
        margin-left: 25%;
   
       
    }

    .row{
        display: block;
        
    }

    .paragraphColumn{
        display: none;
    }

    .stepsIcon{
     width: 20%;
     margin-top: 20px;
   
    
    }

    .stepsHeader{
        padding-right: 0px;
        border-right: none;

    }


    
}

@media screen and (max-width: 800px) {
    .stepsContainer{
        margin: 0px;
        background-attachment: scroll; 
    }

    .stepsIcon{
        width: 15%;
        margin-top: 20px;
        margin-right: 20px;
       
       }

       .stepsHeader{
       font-size: 20px;
    }

}